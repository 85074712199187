import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 30;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>遊べるパートナーが身近にいると始めやすい</li>
        <li>共通の想いをもった人を見つけるのは難しい</li>
        <li>40歳過ぎて毎週末趣味に行ける人が少ないよね</li>
        <li>子育て真っ最中の方とは遊ぶ時間がとれない</li>
        <li>釣りに一緒に行く仲間の探すには</li>
        <li>突き詰めていってしまう各々の釣りのこだわり</li>
        <li>新しい人と仲良くなる前に警戒心と踏み込むのに躊躇する瞬間</li>
        <li>趣味なのに一方的な価値観を押し付けないで</li>
        <li>人に束縛されたくない40代</li>
        <li>サーフィンでのローカルルール</li>
        <li>日本人のこだわりの強さ</li>
        <li>それぞれの価値観の違いを大切にしたい</li>
        <li>どうしても譲れない部分の境界線</li>
        <li>価値観が違う人とどううまく付き合うためのバランス力</li>
        <li>嫌な想いや経験をしているのは価値でありスキルである</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
